div.ReactTags__tags {
    position: relative;
}
div.ReactTags__tagInput {
    width: 100%;
    border-radius: 2px;
    /* display: inline-block; */
}
div.ReactTags__selected {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    /* justify-content: space-between; */
}
div.ReactTags__tagInput input.ReactTags__tagInputField {
    /* height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    border: 1px solid #eee; */
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    /* appearance: menulist; */
}
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%); 
}
div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ced4da;
    background: #ffffff;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-top: 10px;
    /* margin: 0 5px; */
    border-radius: 0.25rem;
    width: 50%;
    text-align: center;
    cursor: initial !important;
}
div.ReactTags__selected span.ReactTags__tag button {
    appearance: auto;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: right;
    align-items: flex-end;
    cursor: pointer;
    color: #ffffff;
    background-color: #25A1FF;
    box-sizing: border-box;
    font: 400 14px Arial;
    padding: 1px 6px;
    border-width: 1px;
    border-color: #25A1FF;
    border-radius: 3px;
    margin: 0 5px;
}
div.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}
div.ReactTags__suggestions {
    width: 100%;
    position: absolute;
    z-index: 1;
}
div.ReactTags__suggestions ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0,0,0,.2);
    background: white;
    width: 100%;
    font-size: 16px;
}
div.ReactTags__suggestions li {
    /* border-bottom: 1px solid #ddd; */
    padding: 2px 10px;
    margin: 0;
}
div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #25A1FF;
    color: #ffffff;
    cursor: pointer;
}
