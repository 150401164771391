body {
  margin: 0;
  padding-top : 58px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f2f5 !important;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*DISABLING THE SPIN BUTTON OPTION ON INPUTS*/
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.form-group.required .control-label:after {
  content:"*";
  color:red;
}

.navbar {
  background: linear-gradient(rgba(29,181,254,1) 0%, rgb(2, 20, 82) 150%);
}

.dropdown-toggle::after {
  margin-top: 0.3em;
}

.navbar-dark .navbar-nav .nav-link {
  display: flex;
  align-items: center;
}

@media (max-width: 1280px) {
  .navbar-dark .navbar-nav .nav-link {
    font-size: 13px;
  }
}

.btn-padding-fix {
  padding-top: 7px!important;
}

.btn, .btn-success, .btn-sm {
  white-space: nowrap;
  height: auto;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.not-visible {
    visibility: hidden;
}

#loginForm .btn-link {
  color: #ffffff;
}

.form-control-plaintext--pagesize {
  margin-left: 50px;
}

.form-control, table thead tr th, table tbody tr {
  cursor: pointer;
}
.table-responsive {
  overflow-x: auto;
}
.table tr {
  height: 55px!important;
}
.form-control {
  white-space: nowrap;
}

table tbody tr td .tab-content, table tbody tr td .form-horizontal, form#programForm {
  padding: 20px 0;
}

.fade.bg-light.border.tab-pane.active.show {
  padding: 20px 10px;
}

table.table tbody tr.plus {
  height: 0 !important;
  border-bottom: none;
}

table.table tbody tr.plus td::after {
  content: none;
}

table.table tbody tr.plus + tr {
  border-top: none;
}

table tbody tr.plus td {
  -webkit-text-fill-color: transparent;
  padding: 0;
  padding-bottom: 5px;
}

input[type="checkbox"] {
    width: 15px;
}

.data-picker-container{
    min-width: 126px !important;
}

.navbar-nav a:after{
    display: none;
}

.navbar-nav .dropdown-menu.show{
    left: -118px;
}

thead > tr:nth-child(1) > td{
    border: 0px solid transparent !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
    padding-top: 30px;
    padding-bottom: 40px;
}

thead > tr:nth-child(2)  .row{
    margin-right: 0 !important;
    margin-left: 0 !important;

}
tbody .row{
    margin-right: 0 !important;
    margin-left: 0 !important;

}

.customer-container-form + .tab-content{
  padding-top: 0px !important;

}

.customer-container-form + .tab-content .bg-light.border, #projectForm, #programForm, #taskForm, #teamForm, #userForm, #userSkillsForm {
    background-color: #f0f2f5 !important;
    border-top: 0px solid transparent !important;
    border-left: 1px solid #dee2e6 !important;
    border-right: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;
}

#userSkillsForm .form-group {
  padding-top: 2rem;
  border-bottom: 1px solid #c3c8cc;
}

.user-skill-col {
  padding-left: 2.5rem;
}

.category-skills-user {
  font-weight: normal;
  color: #5A6B7B;
  text-transform: uppercase;
  font-size: 14px;
}

.btn-skills-user {
  padding-top: 2rem;
}

.nav-item.nav-link.active{
  background-color: #f0f2f5 !important;
  border-color: #dee2e6 #dee2e6 #d8e2ec;
}

.buttons-to-left > .col-sm-2:nth-child(2){
  max-width: 7.666667% !important;
}

.buttons-to-left > .col-sm-2:nth-child(3){
  max-width: 7.666667% !important;
}

.buttons-to-left > .col-sm-2:nth-child(4){
  max-width: 7.666667% !important;
}

.buttons-to-left > .col-sm-2:nth-child(5){
  max-width: 7.666667% !important;
}

.fixed-top {
  z-index:9998!important;
}

/* STICKY HEADER WITH CALENDAR STYLING */
.table-worklist tr:nth-child(1) th {
  position: sticky;
  position: -webkit-sticky;
  background-color: #d8e2ec;
  top: 65px;
  z-index: 2999;
}
.table-worklist tr:nth-child(2) th { /* sticky header day number */
  position: sticky;
  position: -webkit-sticky;
  background-color: #d8e2ec;
  top: 66px;
  z-index: 2000!important;
}
.table-worklist tr:nth-child(2) th:nth-child(1),
.table-worklist tr:nth-child(2) th:nth-child(2),
.table-worklist tr:nth-child(2) th:nth-child(3),
.table-worklist tr:nth-child(2) th:nth-child(4),
.table-worklist tr:nth-child(2) th:nth-child(5),
.table-worklist tr:nth-child(2) th:nth-child(6),
.table-worklist tr:nth-child(2) th:nth-child(7) {
  z-index: 2999!important;
}

.table-worklist tr:nth-child(3) th { /* sticky header week days */
  position: sticky;
  position: -webkit-sticky;
  background-color: #d8e2ec;
  top: 120px;
  z-index: 3000;
}
.table-worklist tr:nth-child(3) th:nth-child(1),
.table-worklist tr:nth-child(3) th:nth-child(2),
.table-worklist tr:nth-child(3) th:nth-child(3),
.table-worklist tr:nth-child(3) th:nth-child(4),
.table-worklist tr:nth-child(3) th:nth-child(5),
.table-worklist tr:nth-child(3) th:nth-child(6),
.table-worklist tr:nth-child(3) th:nth-child(7) {
  z-index: 3001!important;
}

.table-worklist tbody tr td:nth-child(1),
.table-worklist tbody tr td:nth-child(2),
.table-worklist tbody tr td:nth-child(3),
.table-worklist tbody tr td:nth-child(4),
.table-worklist tbody tr td:nth-child(5),
.table-worklist tbody tr td:nth-child(6),
.table-worklist tbody tr td:nth-child(7) {
  z-index: 3000!important;
}
/* STICKY HEADER WITH CALENDAR STYLING - END */

/* HIGHLIGHT COLUMN AND ROW ON HOVER */
.table-worklist {
  -webkit-clip-path: inset(0); /* safari*/
  clip-path: inset(0);
}
.table-worklist td, th {
  position: relative;
  outline: 0;
}
.table-worklist tbody tr:not(:last-child):hover {
  background-color: #dcebf3;
}
.table-worklist tbody td:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)):not(:nth-child(6)):not(:nth-child(7)):hover::after {
  content: '';
  height: 2000px;
  left: 0;
  position: absolute;
  top: -1000px;
  width: 100%;
  z-index: -1;
}
/* HIGHLIGHT OVERLAYING ON VERTICAL AXIS FIXES: */
.table-worklist tbody tr:nth-last-child(12) td:hover::after {  /* 11th row from bottom */
  height: 1750px!important;
  top: -1000px!important;
}
.table-worklist tbody tr:nth-last-child(11) td:hover::after {  /* 10th row from bottom */
  height: 1700px!important;
  top: -1000px!important;
}
.table-worklist tbody tr:nth-last-child(10) td:hover::after {  /* 9th row from bottom */
  height: 1650px!important;
  top: -1000px!important;
}
.table-worklist tbody tr:nth-last-child(9) td:hover::after {  /* 8th row from bottom */
  height: 1550px!important;
  top: -1000px!important;
}
.table-worklist tbody tr:nth-last-child(8) td:hover::after {  /* 7th row from bottom */
  height: 1500px!important;
  top: -1000px!important;
}
.table-worklist tbody tr:nth-last-child(7) td:hover::after {  /* 6th row from bottom */
  height: 1450px!important;
  top: -1000px!important;
}
.table-worklist tbody tr:nth-last-child(6) td:hover::after {  /* 5th row from bottom */
  height: 1400px!important;
  top: -1000px!important;
}
.table-worklist tbody tr:nth-last-child(5) td:hover::after {  /* 4th row from bottom */
  height: 1350px!important;
  top: -1000px!important;
}
.table-worklist tbody tr:nth-last-child(4) td:hover::after {  /* 3rd row from bottom */
  height: 1250px!important;
  top: -1000px!important;
}
.table-worklist tbody tr:nth-last-child(3) td:hover::after {  /* 2nd row from bottom */
  height: 1200px!important;
  top: -1000px!important;
}
.table-worklist tbody tr:nth-last-child(2) td:hover::after {  /* 1st row from bottom */
  height: 1000px!important;
  top: -1000px!important;
}
.table-worklist tbody tr:last-child td:hover::after {         /* hours summary row bottom */
  height: 0px!important;
  top: 0px!important;
}
/* OVERLAYING FIXES END */

.table-worklist td:hover::after,
.table-worklist th:hover::after {
  background-color: #dcebf3;
}
.table-worklist td:focus::after,
.table-worklist th:focus::after {
  background-color: lightblue;
}
/* HIGHLIGHT COLUMN AND ROW ON HOVER - END */

.slider-padding {
    padding-left: 18px!important;
    padding-right: 18px!important;
    z-index: 300!important;
}

/* head table style  */

h2.headline-main-style {
  color: #5A6B7B;
  font-weight: normal;
  text-transform: uppercase;
  padding-top: 30px;
  padding-bottom: 40px;
}

.table thead tr td {
  padding-left: 0;
}

.table thead h2 {
  color: #5A6B7B;
  font-weight: normal;
  text-transform: uppercase;
}

.table thead tr:nth-child(3) {
  background-color: #d8e2ec;
  border: 1px solid #c3c8cc;
  border-bottom: none;
}

.table thead tr:last-child {
  background-color: #d8e2ec;
  border: 1px solid #c3c8cc;
}

.table thead tr:last-child th {
  vertical-align: middle !important;
  font-weight: normal;
  color: #5A6B7B;
  text-transform: uppercase;
  font-size: 14px;
}

/* form add content  */

/* .customer-container-form + .tab-content .bg-light.border, #projectForm, #programForm, #taskForm, #teamForm {
  background-color: #d8e2ec !important;
} */

/* .nav-item.nav-link.active {
  background-color: #d8e2ec !important;
  border-color: #dee2e6 #dee2e6 #d8e2ec;
} */

/* .tab-pane.active table.table thead tr, .tab-pane.active table.table tbody tr {
  background-color: #d8e2ec !important;
} */

tbody p {
  margin-top: 1rem;
}

/* date picker  */

div.DateRangePicker_d1ib85 {

}

div.DateRangePickerInput_ajf2r2-o_O-DateRangePickerInput__withBorder_cizprd {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

div.DateInput_17740uu,
div.DateInput_1lgcb1x,
div.DateInput_1yzewtf {
  max-width: 75px;
  /* width: 50% !important; */
  width: 44% !important;
}
input.DateInput_input_qbj3jy {
  font-size: 1rem !important;
  font-weight: normal !important;
  padding: 0.375rem 0.75rem !important;
  height: calc(1.5em + 0.75rem + 2px);
  line-height: 1.5 !important;
}
input.DateInput_input_qbj3jy-o_O-DateInput_input__focused_1c31qxk {
  font-size: 1rem !important;
  font-weight: normal !important;
  padding: 0.375rem 0.75rem !important;
  height: calc(1.5em + 0.75rem + 2px);
  line-height: 1.5 !important;
}

.DateRangePickerInput_arrow_svg_1mjenpg {
  height: 16px !important;
  width: 16px !important;
}

/* calendar picker  */

td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__selected_start_3hmsj-o_O-CalendarDay__selected_7wh551,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__selected_end_3hmsj-o_O-CalendarDay__selected_7wh551,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__today_3hmsj-o_O-CalendarDay__selected_start_3hmsj-o_O-CalendarDay__selected_7wh551,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__selected_start_3hmsj-o_O-CalendarDay__selected_7wh551:hover,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__selected_end_3hmsj-o_O-CalendarDay__selected_7wh551:hover,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__today_3hmsj-o_O-CalendarDay__selected_start_3hmsj-o_O-CalendarDay__selected_7wh551:hover,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__selected_start_3hmsj-o_O-CalendarDay__selected_7wh551:active,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__selected_end_3hmsj-o_O-CalendarDay__selected_7wh551:active,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__today_3hmsj-o_O-CalendarDay__selected_start_3hmsj-o_O-CalendarDay__selected_7wh551:active,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__firstDayOfWeek_3hmsj-o_O-CalendarDay__selected_start_3hmsj-o_O-CalendarDay__selected_7wh551,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__firstDayOfWeek_3hmsj-o_O-CalendarDay__selected_start_3hmsj-o_O-CalendarDay__selected_7wh551:active,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__lastDayOfWeek_3hmsj-o_O-CalendarDay__selected_end_3hmsj-o_O-CalendarDay__selected_7wh551,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__today_3hmsj-o_O-CalendarDay__firstDayOfWeek_3hmsj-o_O-CalendarDay__selected_span_kibos5,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__today_3hmsj-o_O-CalendarDay__firstDayOfWeek_3hmsj-o_O-CalendarDay__selected_span_kibos5:hover,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__firstDayOfWeek_3hmsj-o_O-CalendarDay__selected_start_3hmsj-o_O-CalendarDay__selected_7wh551:hover {
  background: rgb(37, 161, 255) !important;
  border: 1px double rgb(255, 255, 255) !important;
}

td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__selected_span_kibos5,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__firstDayOfWeek_3hmsj-o_O-CalendarDay__selected_span_kibos5,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__lastDayOfWeek_3hmsj-o_O-CalendarDay__selected_span_kibos5,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__today_3hmsj-o_O-CalendarDay__selected_span_kibos5,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__hovered_span_1a095ve:hover {
  background: rgb(95, 178, 241) !important;
  border: 1px double rgb(255, 255, 255) !important;
}

td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__selected_span_kibos5:hover,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__firstDayOfWeek_3hmsj-o_O-CalendarDay__selected_span_kibos5:hover,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__lastDayOfWeek_3hmsj-o_O-CalendarDay__selected_span_kibos5:hover,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__today_3hmsj-o_O-CalendarDay__selected_span_kibos5:hover,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__selected_span_kibos5:active,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__firstDayOfWeek_3hmsj-o_O-CalendarDay__selected_span_kibos5:active,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__lastDayOfWeek_3hmsj-o_O-CalendarDay__selected_span_kibos5:active,
td.CalendarDay_12fun97-o_O-CalendarDay__default_1nme8vm-o_O-CalendarDay__today_3hmsj-o_O-CalendarDay__selected_span_kibos5:active{
  background: rgb(84, 171, 236) !important;
  border: 1px double rgb(255, 255, 255) !important;
}

.DayPicker_transitionContainer_6vx1r9-o_O-DayPicker_transitionContainer__vertical_zdxht7 {
  height: 440px !important;
}

/* changes for bootstrap styles */
table.table td {
  vertical-align: middle;
}

table.table thead tr {
  border: none;
}

table.table th, table.table td {
  border-top: none;
}

tr th {
  font-weight: normal;
}

table.table thead th {
  border-bottom: none;
}

table.table tbody tr {
  background-color: #ffffff;
  border: 1px solid #c3c8cc;
}

.table-sm th, .table-sm td {
  padding-top: .2rem;
  padding-bottom: .2rem;
  padding-left: 4px;
  padding-right: 4px;
}

/* input inside */

.nav-tabs .nav-link {
  color: #5A6B7B;
  border-color: #dee2e6 #dee2e6 #d8e2ec;
  margin-right: 5px;
}

.tab-pane.active table.table thead tr,
.tab-pane.active table.table tbody tr {
  background-color: #f0f2f5;
}

/* changes for button  */

.btn-success,
.btn-grey-container .btn-success,
.btn-primary, .btn-secondary {
  color: #fff;
  background-color: #25A1FF;
  border-color: #25A1FF;
}

.btn-success:hover,
.btn-grey-container .btn-success:hover,
.btn-primary:hover, .btn-secondary:hover {
  color: #fff;
  background-color: #1a8adf;
  border-color: #177cc9;
}

.btn-success:focus, .btn-success.focus,
.btn-primary:focus, .btn-secondary:focus {
  color: #fff;
  background-color: #1a8adf;
  border-color: #177cc9;
  box-shadow: 0 0 0 0.2rem rgba(23, 124, 201, 0.5);
}

.btn-success.disabled, .btn-success:disabled,
.btn-primary.disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #25A1FF;
  border-color: #25A1FF;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #177cc9;
  border-color: #177cc9;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 124, 201, 0.5);
}

.btn-overlap-container input.btn-success {
  width: 80px;
}

.btn-overlap-team-container input.btn-success {
  /* width: 140px; */
}

table.table .btn.btn-link {
  /* border: 1px solid #25A1FF; */
  background-color: #e1f5fe;
  color: #666;
}

table.table .btn.btn-link:hover {
  background: #25A1FF;
  color: #fff;
  text-decoration: none;
}

/* timereport  */

.timereport--btn-container {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.main-timesheets-container {
  font-size: 14px;
}
.main-timesheets-container h2 {
  color: #5A6B7B;
  font-weight: normal;
  text-transform: uppercase;
  padding-top: 30px;
  padding-bottom: 40px;
}

.timereport--btn {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgb(37, 161, 255);
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.timereport--select {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 2rem 0.375rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* Removes the default <select> styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Positions background arrow image */
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC');
  background-repeat: no-repeat;
  background-position: 90% center;
}

.main-timesheets-container .nav-item.nav-link.active {
  background-color: #d8e2ec !important;
  border: 1px solid #c3c8cc;
  border-bottom: none;
}

.main-timesheets-container .nav-item.nav-link {
  border: 1px solid #c3c8cc;
  border-bottom: none;
}

thead tr.tablehead-worklist-row th {
  vertical-align: middle !important;
}

.timesheets-icon {
  /* display: block; */
  /* margin-right: 6px; */
  /* margin-left: 6px; */
}

div.range-slider__wrap div.range-slider__tooltip.range-slider__tooltip--bottom {
  top: 28px;
}

table.table thead tr.tablehead-worklist-row {
  border-left: 1px solid #c3c8cc;
  border-right: 1px solid #c3c8cc;
  border-bottom: 1px solid #c3c8cc;
}

.tab-pane.active table.table thead tr.tablehead-worklist-row-1 th {
  background-color: #d8e2ec !important;
  /* border-color: #25A1FF !important; */
}

/* .tab-pane.active table.table thead tr.tablehead-worklist-row-2 th:first-child {
  padding-right: 30px;
} */

/* .tab-pane.active table.table thead tr.tablehead-worklist-row-2 th:nth-child(2) {
  padding-right: 60px;
} */

.tab-pane.active table.table.table-worklist tbody tr {
  background-color: #ffffff;
}

.range-slider__wrap .range-slider__tooltip.range-slider__tooltip--bottom {
  top: 28px !important;
  bottom: auto;
}

table.table span.range-slider__wrap input:focus {
  background-color: #d8e2ec;
  box-shadow: none;
}

table.table.table-worklist tbody tr td::after {
  border-left: none;
}

table.table-worklist td input {
  width: 30px;
  border-radius: 3px;
  border-width: 1px;

}

tr.worklist-total-result-hours td {
  border-top: 1px solid #c3c8cc;
  border-bottom: 1px solid #c3c8cc;
  text-align: center;
}

tr.worklist-total-result-hours td:first-child {
  border-left: 1px solid #c3c8cc;
}

tr.worklist-total-result-hours td:last-child {
  border-right: 1px solid #c3c8cc;
}

tr td:first-child {
  max-width: 50px !important;
}

.project-code-th {
  min-width: 140px;
}

/* style td */

table.table tbody tr:last-child {
  background-color: #f0f2f5;
  border: none;
}

table.table tbody tr td {
  position: relative;
}

table.table tbody tr td::after {
  content:"";
  position:absolute;
  left: 0;
  bottom: 25%;
  top: 25%;
  border-left: 1px dotted #c3c8cc;
}

table.table tbody tr td:first-child::after {
  content: none;
}

/* style for Administration and Raports */

.nav-tabs-exception .nav-item.nav-link.active {
  color: #fff;
  background-color: #25A1FF !important;
  border-color: #25A1FF;
}

.nav-tabs-exception .nav-item.nav-link {
  background-color: #ffffff !important;
}

.nav-tabs-exception + .tab-content {
  background-color: #ffffff;
  padding: 2rem;
  border: 1px solid #c3c8cc;
}

.nav-tabs-exception + .tab-content .tab-pane.active table.table thead tr,
.nav-tabs-exception + .tab-content .tab-pane.active table.table tbody tr {
  background-color: #ffffff;
}

.nav-tabs-exception + .tab-content .tab-pane.active table.table thead tr:nth-child(3) {
  background-color: #d8e2ec;
  border: 1px solid #c3c8cc;
  border-bottom: none;
}

.nav-tabs-exception + .tab-content .tab-pane.active table.table thead tr:last-child {
  background-color: #d8e2ec;
  border: 1px solid #c3c8cc;
}

.nav-tabs-exception + .tab-content .tab-pane.active table.table thead tr:last-child th {
  vertical-align: middle !important;
  font-weight: normal;
  color: #5A6B7B;
  text-transform: uppercase;
  font-size: 14px;
}

.table thead tr:last-child {
  background-color: #d8e2ec;
  border: 1px solid #c3c8cc;
}

.table thead tr:last-child th {
  vertical-align: middle !important;
  font-weight: normal;
  color: #5A6B7B;
  text-transform: uppercase;
  font-size: 14px;
}

th div.DateRangePicker_d1ib85 {
  display: block !important;
}

div.justify-content-around {
  justify-content: space-between !important;
  padding-left: 1rem;
}

div.justify-content-around table {
  margin-bottom: 1rem;
}

div.justify-content-around table tbody tr th {
  background-color: #d8e2ec;
}

div.justify-content-around table tbody tr th,
div.justify-content-around table tbody tr td {
  padding: .5rem;
  border: 1px solid #c3c8cc;
}

/* style for mobile  */

@media (max-width:470px) {
  body {
    font-size: 12px !important;
  }
  table, .form-control, .custom-select, .table thead tr:last-child th, input.DateInput_input_qbj3jy, .form-control-plaintext, .btn, .btn-success, .btn-sm {
    font-size: 12px !important;
  }
  .table-sm th, .table-sm td {
    padding: 2px;
  }
}

/* profile style  */

.jumbotron.profile-container {
  background-color: #f0f2f5;
  padding: 1rem;
}

.headline-version {
  color: #5A6B7B;
  font-weight: normal;
  font-size: 1rem;
  padding-bottom: 3rem;
}

.profile-box-container {
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.profile-main-headline {
  color: #5A6B7B;
  font-weight: normal;
  text-transform: uppercase;
  /* padding-bottom: 1rem; */
}

.profile-main-headline-2row {
  padding-top: 2rem;
}

.my-headline {
  margin-bottom: 1rem;
  margin-top: 0;
}

.modal-dialog .modal-content {
  margin-top: 100px;
}

.modal-dialog .modal-content .modal-header {
  border-bottom: none;
  align-items: center;
  display: block;
}

.modal-dialog .modal-content .modal-header .modal-title {
  color: #5A6B7B;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
}

@media screen and (min-width: 576px) {
  .jumbotron.profile-container {
    padding: 3rem 2rem;
  }
  .profile-box-container {
    padding: 3rem 2rem;
    margin-bottom: 2rem;
  }
}

/* toastify  */

.Toastify__toast-container--top-right {
  top: 10% !important;
}

/* reports  */

/* .nav-tabs-exception + .tab-content .tab-pane.active table.table thead tr:last-child th */

table.table .thead-t-report tr.tr-t-report {
  background-color: #25a1ff;
}

.nav-tabs-exception + .tab-content .tab-pane.active table.table .thead-t-report tr.tr-t-report th {
  vertical-align: bottom !important;
  padding-right: 30px;
}

table.table thead th {
  vertical-align: middle !important;
}

table.table thead tr th a,
table.table thead th a {
  padding: 8px 10px;
  border-radius: 5px;
  border: 1px solid #25A1FF;
  color: #25A1FF;
  background-color: #e1f5fe;
  white-space: nowrap;
  text-decoration: none;
  font-weight: normal;
}

table.table thead tr th a:hover,
table.table thead th a:hover {
  background: #25A1FF;
  color: #fff;
  text-decoration: none;
}

/* tooltip  */

.tooltip {
  position: absolute;
  /* z-index: 1070; */
  z-index: 999999;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #5A6B7B;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #5A6B7B;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #5A6B7B;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #5A6B7B;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #5A6B7B;
  border-radius: 0.25rem;
}

/* member input  */

.choose-members .choose-members-input button.css-1r4vtzz, button.css-48ayfv, button.css-1r4vtzz {
  display: flex;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: none;
}

button.css-48ayfv + div {
  border: 1px solid #c3c8cc;
}

.choose-members .choose-members-input button.css-1r4vtzz span.css-1gpjby2, button.css-48ayfv span.css-1gpjby2 {
  display: inline-block;
}

.choose-members .choose-members-input button.css-48ayfv + div {
  width: 100%;
  margin-top: 0 !important;
  box-shadow: .05em .01em .5em rgba(0,0,0,.2);
}

table.table input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

table.table input + div ul {
  background-color: #ffffff;
  box-shadow: .05em .01em .5em rgba(0,0,0,.2);
}

table.table input + div ul li {
  padding: 2px 10px;
}

table.table input + div ul li:hover {
  background-color: #25A1FF !important;
    color: #ffffff;
    cursor: pointer;
}

.add-space-top-to-btn {
  margin-top: 1rem;
}

.tab-container-editform + .tab-content {
  padding: 0;
}

/* log window  */

h2.log-window-headline {
  color: white;
    text-transform: uppercase;
    font-weight: normal;
    text-align: center;
    padding: 1rem 0;
}

.log-window-container {
  padding: 10px 20px;
}

.log-window-container .log-window-form .form-group {
  margin-bottom: 10px;
}

.log-window-container .log-window-form .control-label {
  color: #ffffff;
  margin-bottom: 0;
}

.log-window-container .log-window-form .control-label + div {
  flex: none;
  max-width: 500px;
  width: 100%;
}

.log-window-container .log-window-form .log-window-btns {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

.log-window-container .log-window-form .log-window-btns .btn.btn-link {
  padding-right: 0;
  padding-left: 0;
  width: 50%;
}

.log-window-container .log-window-form .log-window-btns div {
  flex: none;
  padding: 0;
  width: 50%;
}

@media screen and (min-width: 576px) {
  .log-window-container .log-window-form .control-label {
    max-width: 200px;
    width: 100%;
    flex: none;
  }
}

section#cover {
  position: relative;
  height: 90vh;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  position: static !important;
  background-color: #d8e2ec !important;
}

.intro-container {
  width: 100%;
  position: absolute;
  top: 0;
  /* inset: 10% auto auto auto; */
  /* margin-right: -50%; */
}

@media screen and (min-width: 576px) {
  .intro-container {
    top: 4vh;
  }
}

@media screen and (min-width: 1920px) {
  .intro-container {
    top: 12vh;
  }
}

.intro-container .intro img {
  display: block;
  margin: 0 auto;
  max-width: 160px;
  width: 100%;
  height: auto;
}

@media screen and (min-width: 576px) {
  .intro-container .intro img {
    max-width: 200px;
  }
}

.intro-container .intro .intro-desc,
.copyrights-container .copyrights-desc {
  text-align: center;
  color: #5A6B7B;
}

.intro-desc .intro-desc-signup {
  color: #25A1FF;
}

.intro-desc .intro-desc-signup:hover {
  color: #1a8adf;
}

.copyrights-container .copyrights-desc {
  font-size: 14px;
}

.copyrights-container {
  width: 100%;
  position: absolute;
  bottom: 0;
  /* top: 80%; */
}

/* sort filter  */

.arrow-sort-filter {
  /* font-size: 20px; */
  font-weight: bold;
}